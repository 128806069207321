const customerManager = new function () {
    const vuexKey = 'ds_ucommerce_vuex'

    this.getCustomerFromStore = function () {
        const vuex = JSON.parse(window.localStorage.getItem(vuexKey))
        if (vuex)
            return vuex

        return null
    }

    this.setCustomerToStore = function (customer) {
        const vuex = JSON.parse(window.localStorage.getItem(vuexKey))

        if (vuex) {
            if (vuex.state) {
                vuex.state.Customer = customer
            } else {
                vuex.state = {
                    Customer: customer,
                }
            }
            window.localStorage.setItem(vuexKey, JSON.stringify(vuex))
        } else {
            const storeVuex = {
                state: {
                    Customer: customer,
                },
            }
            window.localStorage.setItem(vuexKey, JSON.stringify(storeVuex))
        }
    }

    this.setCustomerNameElement = function (customerName) {
        const matches = document.querySelectorAll('[data-customer-name-display]')
        matches.forEach(el => el.innerHTML = `: ${customerName}`)
    }

    this.setUserAdminElement = function (canManageUsers) {
        if (canManageUsers) {
            const matches = document.querySelectorAll('[data-user-admin-link]')
            matches.forEach(el => el.style.display = 'list-item')
        }
    }

    this.clearCustomer = function () {
        const vuex = JSON.parse(window.localStorage.getItem(vuexKey))
        if (vuex) {
            if (vuex.state) {
                vuex.state.Customer = null
            } else {
                vuex.state = {
                    Customer: null,
                }
            }
            window.localStorage.setItem(vuexKey, JSON.stringify(vuex))
        }
    }

    this.syncActiveCustomer = function (useCache = true) {
        // console.log('syncing active customer');
        fetch(`/authapi/cartlist/customersync?useCache=${useCache}`, {})
            .then((response) => {
                return response.json()
            })
            .then((rdata) => {
                if (rdata) {
                    const data = rdata.Data.data
                    if (data) {
                        const storeCustomer = {
                            isCustomerDistributor: data.IsDistributor,
                            isInternalEmployee: data.IsInternalEmployee,
                            availableCustomers: data.AvailableCustomers,
                            activeCustomer: data.SelectedErpCustomerId,
                            activeCustomerName: data.SelectedCustomerName,
                            userInfo: data.UserInfo,
                            syncTime: new Date(),
                        }

                        customerManager.setCustomerToStore(storeCustomer)
                        customerManager.setCustomerNameElement(data.SelectedCustomerName)
                        customerManager.setUserAdminElement(data.CanManageUsers)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
}()

function ready(fn) {
    if (document.readyState !== 'loading') {
        fn()
        return
    }
    document.addEventListener('DOMContentLoaded', fn)
}

// $(document).ready(() => {
//   window.customerManager = customerManager
// })

ready(() => {
    window.customerManager = customerManager
})
